<template>
  <div class="admin-page">
    <Modal modal-class="slide-up modal_form" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('users.create user')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)" @change="onChangeForm">
            <div class="modal-body">
              <AppInput
                input-style="normal"
                name="name"
                :label="$t('users.name')"
                type="string"
                rules="required"
                v-model="entryToSave.name"
              />

              <AppInput
                input-style="normal"
                name="email"
                :label="$t('users.email')"
                type="email"
                :rules="V_EMAIL_REQUIRED"
                v-model="entryToSave.email"
              />

              <AppInput
                input-style="normal"
                name="phone"
                :label="$t('users.phone')"
                type="number-length:11"
                rules="required|phone"
                v-model="entryToSave.phone"
              />

              <AppSelect
                rules="required"
                input-style="normal"
                :label="$t('users.active')"
                name="active"
                :options-data="status"
                v-model="entryToSave.active"
              />
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      @click="confirmClose">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up modal_form" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('users.update user')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)" @change="onChangeForm">
            <div class="modal-body">
              <AppInput
                input-style="normal"
                name="name"
                :label="$t('users.name')"
                type="string"
                rules="required"
                v-model="entryToEdit.name"
              />

              <AppInput
                input-style="normal"
                name="email"
                :label="$t('users.email')"
                type="email"
                :rules="V_EMAIL_REQUIRED"
                v-model="entryToEdit.email"
                disabled="true"
              />

              <AppInput
                input-style="normal"
                name="phone"
                :label="$t('users.phone')"
                type="number-length:11"
                rules="required|phone"
                v-model="entryToEdit.phone"
              />

              <AppSelect
                rules="required"
                input-style="normal"
                :label="$t('users.active')"
                name="active"
                :options-data="status"
                v-model="entryToEdit.active"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                @click="confirmClose"
              >
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.update_btn') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>

    <BasicTable
      ref="table"
      :table-name="tableName"
      :setting-columns="columns"
      :actions="actions"
      :filters.sync="filters"
      :search="search"
      :setting-apis="apis"
    />

    <ConfirmModal
      ref="modal_delete_entry"
      :modal-header-title="$t('common.confirm_cancel_title')"
      :confirm-handle="deleteEntrySubmit">
    </ConfirmModal>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      create: false,
      active: false,
      tableName: this.$t('users.admin user list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.USERS_LIST,
          params: {"filters[role][equal]": this.$consts.ROLES.ROLE_ADMIN_NAME}
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.USERS_EDIT,
          params: {active: 0}
        }
      },
      actions: {
        editEntry: this.editEntry,
        createEntry: this.createEntry,
        //deleteEntry: true,
        is_admin: true,
        others: [
          {
            showCondition: entry => !entry.email_verified_at,
            label: '認証URLの再送信',
            click: entry => this.resentActiveEmail(entry),
            contentHtml: entry => `<div class="btn-tool default text-center">認証URLの再送信</div>`,
          },
        ]
      },
      search: {
        searchAll: false,
        reset: true,
      },
      filters: {
        name_multi_like: {
          name: 'name',
          type: 'multi_like',
          label: this.$t('users.name'),
          uiType: 'multi_select',
          ui_options: {
              // tags: true,
              closeOnSelect: true,
          },
          options: [],
          boundaryClass: "col-lg-6 col-md-6 col-sm-6",
        },
        active_equal: {
          name: 'active',
          type: 'equal',
          label: this.$t('users.active'),
          uiType: 'select',
          ui_options: {
            // tags: true,
            closeOnSelect: true,
          },
          options: [
            {
              id: "",
              value: "",
            },
            {
              id: 0,
              name: this.$t('users.active_off')
            },
            {
              id: 1,
              name: this.$t('users.active_on')
            },
            {
              id: "none",
              name: this.$t('common.select_all'),
            },
          ],
          boundaryClass: "col-lg-6 col-md-6 col-sm-6"
        }
      },
      status: [
        {
          id: 0,
          name: this.$t('users.active_off')
        },
        {
          id: 1,
          name: this.$t('users.active_on')
        }
      ],
      entrySelectedDelete: {},
      entryToSave: {active: 1},
      entryToEdit: {},
      cloneSave: {},
      columns: [
        {name: 'id', label: "No.", class: 'min-w-80 text-center'},
        {name: 'name', label: this.$t('users.name')},
        {name: 'email', label: this.$t('users.email')},
        {name: 'phone', label: this.$t('users.phone')},
        {name: 'created_at', label: this.$t('users.created_at')},
        {name: 'last_login', label: this.$t('users.last_login')},
        {
          name: 'active',
          label: this.$t('users.active'),
          class: "text-center",
          computedHtml: entry => {
            if (entry.active) {
              return '<span class="alert alert-info">' + this.$t('users.active_on') + '</span>'
            } else {
              return '<span class="alert alert-disable">' + this.$t('users.active_off') + '</span>'
            }
          },
        },
      ]
    }
  },
  components: {BasicTable},
  mounted() {
    this.getNameAccount();
  },
  methods: {
    resentActiveEmail(entry) {
      this.$request.post(this.$consts.API.ADMIN.RESENT_ACTIVE_EMAIL(entry.id)).then(res => {
        if (!res.hasErrors()) {
          this.$appNotice.success(res.data.msg);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      })
    },
    getNameAccount()
    {
      this.$request.get(this.$consts.API.ADMIN.USERS_SELECT_NAME).then(res => {
        if(!res.hasErrors()) {
          this.filters.name_multi_like.options = res.data;
        }
      });
    },
    deleteEntrySubmit()
    {
      if (this.create) {
        this.$refs.modal_create_entry.hide();
      } else {
        this.$refs.modal_edit_entry.hide();
      }

      this.$refs.modal_delete_entry.hide();
      this.active = 0;
    },
    onChangeForm()
    {
      this.active = 1;
    },
    confirmClose()
    {
      this.$refs.modal_delete_entry.show();
      // if (this.create) {
      //   if (!_.isEqual(this.cloneSave, this.entryToSave)) {
      //     this.$refs.modal_delete_entry.show();
      //   } else {
      //     this.$refs.modal_edit_entry.hide();
      //     this.$refs.modal_create_entry.hide();
      //   }
      // } else {
      //   if (!_.isEqual(this.cloneSave, this.entryToEdit)) {
      //     this.$refs.modal_delete_entry.show();
      //   } else {
      //     this.$refs.modal_edit_entry.hide();
      //     this.$refs.modal_create_entry.hide();
      //   }
      // }
    },
    clearEntryToSave() {
      this.create = false;
      this.entryToSave = {active: 1}
      this.$refs.form_create_entry.reset();
    },
    clearEntryToEdit() {
      this.create = false;
      this.$refs.form_update_entry.reset();
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
      this.create = true;
      this.cloneSave = _.cloneDeep(this.entryToSave);
    },
    async saveEntry() {
      const {name, email, active, phone} = this.entryToSave;

      const res = await this.$request.post(this.$consts.API.ADMIN.USERS_SAVE, {
        name, email, phone, active, role: this.$consts.ROLES.ROLE_ADMIN_NAME
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {active: 1};
        this.$refs.table.getEntries();
        this.getNameAccount();
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else if (res.data.error_msg) {
          this.$refs.form_create_entry.setErrors({
            email: [res.data.error_msg]
          });
        } else {
          this.$appNotice.error()
        }
      }
    },

    editEntry(entry) {
      this.entryToEdit = _.clone(entry)
      this.entryToEdit.phone = this.entryToEdit.phone;
      this.cloneSave = _.cloneDeep(this.entryToEdit)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      const {name, email, active, phone} = this.entryToEdit;

      const res = await this.$request.patch(this.$consts.API.ADMIN.USERS_EDIT(this.entryToEdit.id), {
        name, email, phone, active, role: this.$consts.ROLES.ROLE_ADMIN_NAME
      });

      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries();
        this.getNameAccount();

        if (this.entryToEdit.id == this.$auth.user().id) {
          this.$auth.user().name = this.entryToEdit.name;
          this.$router.go();
        }
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else if (res.data.error_msg) {
          this.$refs.form_create_entry.setErrors({
            email: [res.data.error_msg]
          });
        } else {
          this.$appNotice.error()
        }
      }
    },
  }
}
</script>

<style>
  .admin-page .modal_form .close {
    display: none;
  }
  .admin-page .search-content .btn-default{
    margin-left: 7px;
  }
  .select2-container {
    z-index: 9999;
  }
</style>
